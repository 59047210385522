import React from 'react'
import AUX from '../Utils/Aux/Aux'
import Menu from '../Menu/Menu'
import logo from '../Home/logo.jpg'
import styles from '../Home/Home.module.css'
import blue from './blueSmall.png'
import red1 from './red11.png'

const Firma = () => {
  return (
    <AUX>
      <Menu />

      <div className={styles.logoDiv}>
        <img className={styles.logo} src={logo} />
      </div>
      <a
        style={{ outline: 'none' }}
        target="_blank"
        href="https://wizytowka.rzetelnafirma.pl/24K0APZY"
        rel="nofollow"
      >
        <img
          style={{
            width: '160px',
            height: '75px',
            display: 'table-cell',
            border: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '50px',
          }}
          title="Kliknij i sprawdź status certyfikatu"
          alt=""
          src="http://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/13/24K0APZY"
        />{' '}
      </a>
      <div className={styles.logoDiv} style={{ marginTop: '70px' }}>
        <img className={styles.firma} alt="Rzetelna firma" src={blue} />
      </div>

      <a href="https://pmuandlashes.studio" />
    </AUX>
  )
}

export default Firma
