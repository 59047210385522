import React from 'react'
import AUX from '../Utils/Aux/Aux'
import Menu from '../Menu/Menu'
import logo from './logo.jpg'
import maszyna from './iStock-1047076654.jpg'
import rolki from './iStock-1010755712.jpg'
import rolki2 from './iStock-1172233289.jpg'
import styles from './Home.module.css'

const Home = () => {
  console.log(process.env.DATABASE)
  return (
    <AUX>
      <Menu />
      <div className={styles.logoDiv}>
        <img className={styles.logo} src={logo} />
      </div>

      <div className={styles.contentDiv}>
        <div className={styles.content}>
          <img className={styles.photoLeft} src={rolki} />
        </div>
        <div className={styles.contentTextDiv}>
          <p className={styles.contentText}>
            Jesteśmy firmą produkcyjną specjalizującą się w <br />
            wytwarzaniu folii LDPE jednowarstwowej,
            <br /> różnego rodzaju opakowań,
            <br /> oraz nadrukami fleksograficznymi
            <br />6 kolorowymi jedno i dwustronnymi.
          </p>
        </div>
      </div>

      <div className={styles.contentMiddle}>
        <div className={styles.content}>
          <img className={styles.photoRight} src={maszyna} />
        </div>
        <div className={styles.contentTextDiv}>
          <p className={styles.contentText}>
            Zastosowanie najnowszych technologii pozwala na sprostanie <br />
            potrzeb bardzo wymagających kontrahentów.
            <br /> Naszymi odbiorcami są kontrahenci krajowi i zagraniczni;
            <br /> głównie firmy produkyjne,handlowe, jak również agencje
            reklamowe.
          </p>
        </div>
      </div>

      <div className={styles.contentDiv}>
        <div className={styles.content}>
          <img className={styles.photoLeft} src={rolki2} />
        </div>
        <div className={styles.contentTextDiv}>
          <p className={styles.contentText}>
            W naszej ofercie znajdziecie Państwo dużą różnorodność
            <br />
            produktów, co pozwoli kompleksowo zaopatrzyć Wasze przedsiębiorstwo.
            <br /> Służymy pomocą i fachowym doradztwem w doborze najlepszego
            rodzaju opakowania pod względem
            <br />
            jakościowym,estetycznym a także ekonomicznym.
          </p>
        </div>
      </div>
      <a href="https://pmuandlashes.studio" />
    </AUX>
  )
}

export default Home
