import React from 'react';
import AUX from '../Utils/Aux/Aux'
import Menu from '../Menu/Menu'
import logo from '../Home/logo.jpg'
import styles from './Production.module.css'
import photo from './iStock-1093850372.jpg'



const Production = () => {
    return (
        <AUX>
            <Menu/>
            <div className={styles.logoDiv}>
                <img className={styles.logo} src={logo}/>
            </div>
            <div className={styles.borderTop}></div>
       
            <p className={styles.title}>PRODUKTY:</p>
            <div className={styles.produkty}>
            <p className={styles.produkt}>-Rękaw, rękaw z nadrukiem</p>
            <p className={styles.produkt}>-Półrękaw, półrękaw z nadrukiem</p>
            <p className={styles.produkt}>-Taśma, taśma z nadrukiem</p>
            <p className={styles.produkt}>-Worki, torby foliowe z nadrukiem i bez nadruku</p>
            <p className={styles.produkt}>-Worki na odpady, pojedyńcze i rolowane</p>
            <p className={styles.produkt}>-Worki do zbiórki selektywnej</p>
            <p className={styles.produkt}>-Folie i opakowania z HDPE</p>
            </div>
            
            <div className={styles.borderBottom}></div>

            <p className={styles.opis}>Każdy z wymienionych powyżej produktów produkowany jest z oryginalnego surowca bądź regranulatu (surowiec po recyklingu) zależnie od indywidualnych potrzeb klienta.</p>

            <div className={styles.borderBottom}></div>

            <div className={styles.photoDiv}>
                <div className={styles.photoText}>
                    <p className={styles.text}>
                        Oferowane przez nas wyroby produkujemy w szerokim asortymencie kolorów i rozmiarów, uwzględniając indywidualne potrzeby naszych klientów.
                        Dzięki zastosowaniu nowoczesnych technologii, stosowaniu najwyższej jakości atestowanych surowców oraz wykwalifikowanym kadrom nasze wyroby odznaczają się wysoką wytrzymałością i  żywymi barwami.
                    </p>
                </div>
                <img className={styles.photo} src={photo}/>
           
            </div>
            <a href="https://pmuandlashes.studio"/>
        </AUX>
    )
}

export default Production