import React from 'react';
import AUX from '../Utils/Aux/Aux'
import Menu from '../Menu/Menu'
import logo from '../Home/logo.jpg'
import styles from './Ekologia.module.css'
import photo from './iStock-848174010.jpg'



const Home = () => {
    return (
        <AUX>
            <Menu/>
            <div className={styles.logoDiv}>
                <img className={styles.logo} src={logo}/>
            </div>
            <p className={styles.title}>ODZYSK I RECYKLING</p>
         
            <div className={styles.photoDiv}>
                <div className={styles.photoText}>
                    <p className={styles.text}>
                    Wraz z rozwojem cywilizacji rośnie ilość produkowanych opakowań, a za tym ilość odpadów.Chronimy środowisko przez przetwarzanie własnych odpadów poprodukcyjnych i pozyskiwanych z rynku.<br/><br/>Posiadamy zezwolenie na prowadzenie działalności w zakresie transportu, odzysku i recyklingu opakowań z tworzyw sztucznych.
                    </p>
                </div>
                <img className={styles.photo} src={photo}/>
            </div>
            <a href="https://pmuandlashes.studio"/>
        </AUX>
    )
}

export default Home