import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import AUX from '../Utils/Aux/Aux'
import SideDrawer from '../Utils/SideDrawer/SideDrawer'

import classes from './Menu.module.css'

const Menu = () => {
  const [show, setShow] = useState(false)

  const toggleShow = () => {
    setShow(!show)
  }

  return (
    <AUX>
      <div onClick={toggleShow} className={classes.menu}>
        <p>M e n u</p>
      </div>
      <SideDrawer open={show} closed={toggleShow}>
        <div className={classes.linkDiv}>
          <Link to="/home">O firmie</Link>
        </div>
        <div className={classes.linkDiv}>
          <Link to="/produkcja">Produkty</Link>
        </div>
        <div className={classes.linkDiv}>
          <Link to="/ekologia">Ekologia</Link>
        </div>
        <div className={classes.linkDiv}>
          <Link to="/rozwoj">Udział w projektach unijnych</Link>
        </div>
        <div className={classes.linkDiv}>
          <Link to="/firma">Rzetelna firma</Link>
        </div>
        <div className={classes.linkDiv}>
          <Link to="/kontakt">Kontakt i Lokalizacja</Link>
        </div>
      </SideDrawer>
    </AUX>
  )
}

export default withRouter(Menu)
