import React from 'react';
import AUX from '../Utils/Aux/Aux'
import classes from './Location.module.css'
import MapContainer from '../Map/Map'
import Menu from '../Menu/Menu'
import img from './folimer.png'
import logo from '../Home/logo.jpg'
import { withRouter} from 'react-router-dom'

const Location = () => {
    console.log(navigator)
    return (
        <AUX>
            
            <Menu/>
            <div className={classes.overMap}>
            <div className={classes.adressDiv}>
            <div className={classes.logoDiv}>
                <img className={classes.logo} src={logo}/>
            </div>
            <p className={classes.title}>Folimer - Produncent folii i opakowań LDPE</p>
            <div className={classes.border}></div>
            <p className={classes.adress}>21-003 Ciecierzyn,</p>
            <p className={classes.adress}>ul. Mirabelkowa 5</p>
            <p className={classes.adress}>woj.Lubelskie</p>
            <br/>
            <div className={classes.border}></div>
            <p className={classes.adress}>tel.  081 756 30 99</p>
            <p className={classes.adress}>fax  081 756 37 30</p>
            <p className={classes.adress}>e-mail: biuro@folimer.pl</p>
            <br/>
            <div className={classes.border}></div>
            <a rel="noopener noreferrer" target='_blank' href='https://www.google.com/maps/place/Folimer.+Producent+folii+i+opakowa%C5%84+foliowych/@51.2984421,22.57896,18.75z/data=!4m5!3m4!1s0x47225064e03d82ed:0x3cd6fbf90f975cfc!8m2!3d51.2980014!4d22.5789019'><p className={classes.adress}>Otworz w Mapy Google &lt;</p></a>
            <br/>
            </div>
            <div className={classes.imgDiv}>
            <img className={classes.img} alt='dojazd' src={img}/>
            </div>
            </div>
            <MapContainer/>
        </AUX>
    )
}

export default withRouter(Location)