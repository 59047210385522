import React from 'react';
import {Switch, Route } from "react-router-dom";

import Home from './Components/Home/Home'
import Location from './Components/Location/Location'
import Production from './Components/Production/Production'
import Rozwoj from './Components/Rozwoj/Rozwoj'
import Ekologia from './Components/Ekologia/Ekologia'
import Firma from './Components/Firma/Firma'


const Routes = () => {
  return (
   
    <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/kontakt" exact component={Location}/>
        <Route path="/produkcja" exact component={Production}/>
        <Route path="/rozwoj" exact component={Rozwoj}/>
        <Route path="/ekologia" exact component={Ekologia}/>
        <Route path="/firma" exact component={Firma}/>
        <Route path="/*" component={Home}/>
    </Switch>
 
  );
}

export default Routes;
