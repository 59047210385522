import React from 'react';
import AUX from '../Utils/Aux/Aux'
import Menu from '../Menu/Menu'
import logo from '../Home/logo.jpg'
import styles from './Rozwoj.module.css'
import photo from './EU.png'



const Rozwoj = () => {
    return (
        <AUX>
            <Menu/>
            <div className={styles.logoDiv}>
                <img className={styles.logo} src={logo}/>
            </div>
            <div className={styles.logoDiv}>
            <img className={styles.eu} src={photo} />
            </div>
            <p className={styles.title}>"Twój pomysł, europejskie pieniądze"</p>
            <p className={styles.title}>"Wzmocnienie konkurencyjnosci technologicznej, ofertowej i cenowej przedsiebiorstwa FOLIMER Dariusz Mazur"</p>
            <p className={styles.para}>FOLIMER Dariusz Mazur</p>
            <p className={styles.para}>ul Mirabelkowa 5</p>
            <p className={styles.para}>21-003 Ciecierzyn</p>
            <p className={styles.title}>W ramach projektu zakupiono m.in.:</p>
            <p className={styles.para}>-linie do regranulacji NGR</p>
            <p className={styles.para}>-agregat chlodniczy</p>
            <p className={styles.para}>-linie do produkcji folii</p>
            <p className={styles.title}>W wyniku realizacji niniejszego projektu do oferty przedsiebiorstwa zostaly dodane nastepujace produkty:</p>
            <p className={styles.para}>-regranulat</p>
            <p className={styles.para}>-folie i opakowania z HDPE</p>
            <p className={styles.title}>"Calkowita wartosc projektu :<br/> 1 394 460,00 zl"</p>
            <p className={styles.title}>"Kwota dofinansowania z Europejskiego Funduszu Rozwoju Regionalnego:<br/> 800 100,00 zl"</p>
            <p className={styles.title}>"Projekt wspólfinansowany ze srodków Europejskiego Funduszu Rozwoju Regionalnego oraz Budzetu Panstwa w ramach Regionalnego Programu Operacyjnego Województwa Lubelskiego na lata<br/> 2007 - 2013"</p>
            <br/>
            <p className={styles.title}>LUBELSKA AGENCJA WSPIERANIA PRZEDSIEBIORCZOSCI</p>
            <p className={styles.para}>ul. Wojciechowska 9a, 20-704 Lublin.</p>
            <p className={styles.para}>Punkt informacyjny LAWP (81) 46 23 831, (81)46 23 812, www.lawp.lubelskie.pl</p>
            <br/>
            <p className={styles.title}>ODDZIAL FUNDUSZY EUROPEJSKICH</p>
            <p className={styles.para}>Departament Strategii i Rozwoju Regionalnego Urzedu Marszalkowskiego Wojwództwa Lubelskiego</p>
            <p className={styles.para}>ul. Stefczyka 3b, 20-151 Lublin</p>
            <p className={styles.para}>www.feu.lubelskie.pl</p>
            <a href="https://pmuandlashes.studio"/>
        </AUX>
    )
}

export default Rozwoj